<template>
  <b-row class="match-height">
    <b-col
      cols="12"
      md="6"
    >
      <b-card
        no-body
        class="business-card"
      >
        <b-card-header class="pb-1">
          <b-card-title>Loonheffingskorting</b-card-title>
        </b-card-header>

        <b-card-body>
          <b-card-text class="p-2">
            Als je werkt hebt je recht op korting op je te betalen loonbelasting. Je mag deze slechts bij 1 werkgever toepassen. Doe dit waar je het meeste verdient. Te veel ontvangen of te veel betaalde loonbelasting zal je via je aangifte inkomstenbelasting betalen of ontvangen. Je kunt deze later eenvoudig op je account wijzigen.
          </b-card-text>
          <h6 class="mb-75 pl-2 pr-2">
            Keuze:
          </h6>
          <div class="business-items">
            <div
              class="business-item"
            >
              <div class="d-flex align-items-center justify-content-between">
                <b-form-checkbox
                  v-model="payrollTaxCreditChecked"
                  @change="update"
                >
                  Loonheffingskorting toepassen
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-card
        no-body
        class="business-card"
      >
        <b-card-header class="pb-1">
          <b-card-title>Sociale premies</b-card-title>
        </b-card-header>

        <b-card-body>
          <b-card-text class="p-2">We houden alle verplichte premies en belastingen in, maar je bent niet verzekerd voor werkloosheid of arbeidsongeschiktheid indien deze optie uit staat.
            Je kunt dus ook geen aanspraak maken op een uitkering via het UWV indien deze optie uit staat.<br><br></b-card-text>
          <h6 class="mb-75 pl-2 pr-2">
            Keuze:
          </h6>
          <div class="business-items">
            <div
              class="business-item"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <div class="d-flex align-items-left justify-content-between">
                    <b-form-checkbox
                      v-model="socialContributionsChecked"
                      :disabled="agreementSigned===1"
                      @change="update"
                    >
                      Sociale premies via UWV toepassen<br>
                      <small v-if="agreementSigned===1">
                        <feather-icon icon="InfoIcon" />
                        Neem contact op met de klantenservice om deze optie aan te passen</small>
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col
                  cols="6"
                  class="align-items-end"
                />
              </b-row>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardText, BCardTitle,
  BCol, BFormCheckbox,
  BRow,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import payOptionsStoreModule from '@/views/pages/pay-options/payOptionsStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BFormCheckbox,
    BCardBody,
  },
  data() {
    return {
      payrollTaxCreditChecked: false,
      socialContributionsChecked: false,
      agreementSigned: 0,
    }
  },
  beforeMount() {
    this.getPayOptions()
  },
  methods: {
    getPayOptions() {
      store
        .dispatch('app-payoptions/fetchProfile')
        .then(response => {
          const profile = response.data

          this.agreementSigned = profile.agreementSigned

          if (profile.hasLoonHeffingDiscount && profile.hasLoonHeffingDiscount === 1) {
            this.payrollTaxCreditChecked = true
          } else {
            this.payrollTaxCreditChecked = false
          }

          if (profile.hasSocialCharges && profile.hasSocialCharges === 1) {
            this.socialContributionsChecked = true
          } else {
            this.socialContributionsChecked = false
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen verloonopties',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    update() {
      store
        .dispatch('app-payoptions/patchPaymentPreferences', { hasLoonHeffingDiscount: this.payrollTaxCreditChecked ? 1 : 0, hasSocialCharges: this.socialContributionsChecked ? 1 : 0 })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Update verloonopties',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het aanpassen van de verloonopties. Probeer het nogmaals.',
            },
          })
        })
    },
  },
  setup() {
    const PAYSOPTIONS_PAYOUT_APP_STORE_MODULE_NAME = 'app-payoptions'

    // Register module
    if (!store.hasModule(PAYSOPTIONS_PAYOUT_APP_STORE_MODULE_NAME)) store.registerModule(PAYSOPTIONS_PAYOUT_APP_STORE_MODULE_NAME, payOptionsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYSOPTIONS_PAYOUT_APP_STORE_MODULE_NAME)) store.unregisterModule(PAYSOPTIONS_PAYOUT_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
